import React from "react";

import GridRow from "../../commons/GridRow";
import Box from "../../commons/Box";
import Image from "../../commons/Image";
import Text from "../../commons/Text";
import Button from "../../commons/Button";
import RichTextRenderer from "../../commons/RichTextRenderer";
import { gradientBgVertical } from "../../../utils/styles";
import { linkResolver } from "../../../utils/links";
import List from "../../commons/List";

export default function ItemListBlock({
  _rawTitle,
  buttons,
  items,
  style,
  ...otherProps
}) {
  return (
    <GridRow
      as="section"
      sx={{
        py: [5, "gridGap"],
        ...(style === "gradient"
          ? gradientBgVertical
          : { backgroundColor: "blue" }),
      }}
    >
      <GridRow.Col
        gridColumn={["start / end", "start / span 5"]}
        sx={{
          textAlign: ["center", "start"],
          color: "inverted",
        }}
      >
        <RichTextRenderer blocks={_rawTitle} />
        {!!buttons.length && (
          <Box sx={{ mt: 4 }}>
            {buttons.map(linkResolver).map((link) => (
              <Button key={link.to || link.href} {...link} />
            ))}
          </Box>
        )}
      </GridRow.Col>
      <GridRow.Col gridColumn={["start / end", "8 / end"]}>
        <List gap={3}>
          {items.map((item) => (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "background",
                borderRadius: "card",
                alignItems: "center",
                p: 3,
              }}
              key={item._key}
            >
              {item.image && item.image.asset && (
                <Image
                  fluid={item.image.asset.fluid}
                  width={70}
                  height={70}
                  sx={{
                    flexShrink: 0,
                    mr: 3,
                    borderRadius: item.image.asset.metadata.isOpaque
                      ? "circle"
                      : 0,
                  }}
                  alt={item.image.alt}
                />
              )}
              <Text variant="h5" sx={{ fontSize: "body" }}>
                {item.description}
              </Text>
            </Box>
          ))}
        </List>
      </GridRow.Col>
    </GridRow>
  );
}
